<template>
  <el-dialog
    :title="isShow ? '农户支出凭证' : '公司支出凭证'"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="1200px"
    height="1000px"
    center
  >
    <div class="PreviewList">
      <div class="BigImg">
        <!-- <el-image
          :src="
            baseUrl +
            'admin/file/get?ossFilePath=' +
            JSON.parse(PreviewList.files)[0].imgUrl
          "
          alt="图片错误"
        /> -->
        <el-image
        v-if=" JSON.parse(PreviewList.files)[0].imgUrl"
          :src="
            baseUrl +
            'admin/file/get?ossFilePath=' +
            JSON.parse(PreviewList.files)[0].imgUrl
          "
        >
          <div slot="error" class="image-slot">
            <!-- <i class="el-icon-picture-outline"></i> -->

            <img src="../component/icon/imgError.png" />
          </div>
        </el-image>
      </div>
      <div class="IntroduceList">
        <div class="top_box">
          <div class="ItemText">
            <div class="title">凭证编号:</div>
            <div class="content">{{ PreviewList.incomePaymentId }}</div>
          </div>
          <div class="ItemText">
            <div class="title">上传时间:</div>
            <div class="content">{{ PreviewList.createTime }}</div>
          </div>
          <div class="ItemText">
            <div class="title">备注:</div>
            <div class="content">{{ PreviewList.remark }}</div>
          </div>
        </div>
        <div class="center_box">
          <div class="ItemText" v-if="isShow">
            <div class="title">姓名:</div>
            <div class="content">{{ PreviewList.userName }}</div>
          </div>
          <div class="ItemText" v-else>
            <div class="title">名称:</div>
            <div class="content">{{ PreviewList.userName }}</div>
          </div>

          <div class="ItemText" v-if="isShow">
            <div class="title">身份证号:</div>
            <div class="content">{{ PreviewList.creatorIdentityId }}</div>
          </div>

          <div class="ItemText" v-else>
            <div class="title">统一社会信用代码:</div>
            <div class="content">{{ PreviewList.creatorIdentityId }}</div>
          </div>

          <div class="ItemText">
            <div class="title">所在地:</div>
            <div class="content">{{ PreviewList.areaName }}</div>
          </div>

          <div class="ItemText" v-if="!isShow">
            <div class="title">联系人:</div>
            <div class="content">{{ PreviewList.contactName }}</div>
          </div>
          <div class="ItemText" v-if="isShow">
            <div class="title">联系方式:</div>
            <div class="content">{{ PreviewList.phone }}</div>
          </div>
          <div class="ItemText" v-else>
            <div class="title">联系方式:</div>
            <div class="content">{{ PreviewList.phone }}</div>
          </div>
        </div>
        <div class="bottom_box">
          <div
            class="ImgBtn"
            @click="Download(JSON.parse(PreviewList.files)[0])"
          >
            下载图片
          </div>
          <div class="Cancel" @click="Cancel()">关闭</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { download } from "@/api/ChargeUp";
export default {
  data() {
    return {
      dialogVisible: true, //弹窗开启状态
      IsStanding: true, //当前页面状态：支出还是收入
      baseUrl: window.globalUrl.HOME_API,
      isShow: true,
      PreviewList: {}, // 预览列表
    };
  },

  mounted() {},

  methods: {
    // 取消弹窗按钮
    Cancel() {
      this.$emit("closeDialog", false);
    },
    //点击关闭弹窗
    handleClose() {
      this.$emit("closeDialog", false);
    },
    async init(obj) {
      console.log(obj);
      if (obj.creatorType == 2) {
        console.log("这是农户");
        this.isShow = true;
      } else {
        console.log("新型经营组织");
        this.isShow = false;
      }

      this.PreviewList = obj;
    },
    async Download(file) {
      let itemList = file;
      let res = await download({ ossFilePath: itemList.imgUrl });
      if (res) {
        let blob = res;
        let name = this.PreviewList.fileType == "1" ? "收入凭证" : "支出凭证";
        let ToName = itemList.FilesName;
        const fileName = this.PreviewList.userName + "-" + name + "-" + ToName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        // "|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.PreviewList {
  width: 100%;
  height: 100%;
  display: flex;
  .BigImg {
    width: 706px;
    height: 452px;
    padding: 20px;
    /deep/.el-image {
      width: 706px;
      height: 452px;
      .image-slot {
        width: 706px;
        height: 452px;
        img {
          width: 706px;
          height: 452px;
        }
      }
      img {
        width: 671px;
        height: 412px;
      }
    }
  }
  .IntroduceList {
    width: 40%;
    display: flex;
    flex-direction: column;
    .top_box {
      height: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .ItemText {
        width: 100%;
        display: flex;
        height: 20%;
        .title {
          width: 50%;
          text-align: right;
          padding-right: 20px;
          font-weight: bold;
        }
      }
    }
    .center_box {
      height: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border: solid 1px#ccc;
      .ItemText {
        width: 100%;
        display: flex;
        .title {
          width: 50%;
          text-align: right;
          padding-right: 20px;
          font-weight: bold;
        }
      }
    }
    .bottom_box {
      height: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      div {
        width: 60%;
        height: 25%;
        margin-left: 80px;
        text-align: center;
        line-height: 30px;
      }
      .ImgBtn {
        background: #169bd5;
      }
      .Cancel {
        background: #d7d7d7;
      }
    }
  }
}
</style>
